import Core from "./Core";

export default class ChatFoodWidget extends Core {
  orderNow(data = {}) {
    this.showOrderSystem(data);
  }

  shareWidgetState(data = {}) {
    this.widgetState = data;
    this._emit("updateState", data);
  }

  launchWidget() {
    this.launcher.classList.add("chatfood-visible");
    this.greetings.classList.add("chatfood-visible");
  }

  showOrderSystem() {
    if (this._isMobile()) {
      window.location.href = this.config.orderLink;
      return;
    }

    const payload = {
      salt: process.env.VUE_APP_EVENT_SALT,
      event: "release_analytics"
    };

    this.orderSystem.contentWindow.postMessage(payload, "*");

    this.orderSystem.classList.add("chatfood-visible");
    this.backdrop.classList.add("chatfood-visible");
    this.orderButton.classList.remove("chatfood-visible");
    this.launcher.classList.remove("chatfood-visible");
    this.greetings.classList.remove("chatfood-visible");
    document.body.classList.add("chatfood-open");
  }

  closeOrderSystem() {
    this.orderSystem.classList.remove("chatfood-visible");
    this.backdrop.classList.remove("chatfood-visible");
    document.body.classList.remove("chatfood-open");

    this.showOrderButton();
  }

  closeGreetings() {
    this.launcher.classList.remove("chatfood-visible");
    this.greetings.classList.remove("chatfood-visible");

    this.showOrderButton();
  }

  showOrderButton() {
    if (this.widgetState.business.widget_type !== "compact") {
      this.orderButton.classList.add("chatfood-visible");
    }
  }

  setGreetingsHeight(data = {}) {
    this.greetings.setAttribute("style", `height: ${data.height + 7}px;`);
  }
}
