import ChatFoodWidget from "./ChatFoodWidget";
import "./main.scss";
import axios from "axios";

window.addEventListener("load", async function() {
  const info = await axios.get(
    `${process.env.VUE_APP_API_LINK}/storefront/v2/businesses/${
      window["ChatFoodWidgetClient"]
    }/widget`
  );

  window.ChatFoodWidget = new ChatFoodWidget(
    window["ChatFoodWidgetClient"],
    info.data.custom_domain
  );
});
