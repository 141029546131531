export default class Core {
  constructor(businessUrl, customDomain) {
    const widgetBaseLink = process.env.VUE_APP_WIDGET_LINK.replace(/\/$/, "");
    const orderBaseLink = process.env.VUE_APP_ORDER_LINK.replace(/\/$/, "");

    this.config = {
      businessUrl: businessUrl,
      widgetDomain: widgetBaseLink,
      widgetLink: `${widgetBaseLink}/${businessUrl}`,
      orderLink: this._orderLinkBuilder(
        orderBaseLink,
        businessUrl,
        customDomain
      )
    };

    this.container = null;
    this.backdrop = null;
    this.launcher = null;
    this.orderButton = null;
    this.greetings = null;
    this.orderSystem = null;

    this.widgetState = null;

    this._init();
  }

  _orderLinkBuilder(orderBaseLink, businessUrl, customDomain) {

    if (customDomain) {
      return `https://${customDomain}?channel=web`;
    }

    return `${orderBaseLink}/${businessUrl}?channel=web`;
  }

  _init() {
    try {
      this._loadWidget();
    } catch (e) {
      window.console.warn(e);
    }
  }

  __appendStylesFile() {
    let el = document.createElement("link");
    el.setAttribute("href", `${this.config.widgetDomain}/v1/bundle.css`);
    el.setAttribute("rel", "stylesheet");
    document.head.appendChild(el);
  }

  _loadWidget() {
    this.__appendStylesFile();

    this.container = this._createContainerDiv();

    this.backdrop = this._createFrame(
      `${this.config.widgetLink}/backdrop`,
      "cfwBackdrop",
      "chatfood-backdrop-frame"
    );

    this.orderSystem = this._createFrame(
      `${this.config.orderLink}&hold-analytics`,
      "cfwOrderSystem",
      "chatfood-order-system-frame",
      true
    );
    this._inheritQueryString(this.orderSystem);

    this.orderButton = this._createFrame(
      `${this.config.widgetLink}/order-button`,
      "cfwOrderButton",
      "chatfood-order-button-frame"
    );

    this.greetings = this._createFrame(
      `${this.config.widgetLink}/greetings`,
      "cfwGreetings",
      "chatfood-greetings-frame"
    );

    this.launcher = this._createFrame(
      this.config.widgetLink,
      "cfwLauncher",
      "chatfood-launcher-frame"
    );

    this._appendWidgetToBody();

    this._registerMessageListener();
  }

  _isMobile() {
    return (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    );
  }

  _createContainerDiv() {
    let el = document.createElement("div");
    el.classList.add("chatfood-container");

    if (this._isMobile()) {
      el.classList.add("chatfood-container--mobile");
    }

    return el;
  }

  _createFrame(src, id, className, locationIsUsed) {
    let el = document.createElement("iframe");
    el.classList.add(className);
    el.setAttribute("id", id);
    el.setAttribute("frameborder", "0");
    el.setAttribute("src", src);
    locationIsUsed && el.setAttribute("allow", "geolocation");

    return el;
  }

  _emit(event, data) {
    const payload = {
      salt: process.env.VUE_APP_EVENT_SALT,
      event,
      data
    };

    this.backdrop.contentWindow.postMessage(payload, "*");
    this.launcher.contentWindow.postMessage(payload, "*");
    this.orderButton.contentWindow.postMessage(payload, "*");
    this.greetings.contentWindow.postMessage(payload, "*");
  }

  _registerMessageListener() {
    window.addEventListener("message", event => {
      const eventData = event.data;

      if (eventData.salt !== process.env.VUE_APP_EVENT_SALT) return;

      try {
        this[eventData.event](eventData.data);
      } catch (e) {
        window.console.warn(eventData, `[ChatFoodWidget]: ${e}`);
      }
    });
  }

  _appendWidgetToBody() {
    this.container.appendChild(this.backdrop);
    this.container.appendChild(this.orderSystem);
    this.container.appendChild(this.orderButton);
    this.container.appendChild(this.greetings);
    this.container.appendChild(this.launcher);

    document.body.appendChild(this.container);
  }

  _inheritQueryString(el) {
    const queryString = window.location.search;

    if (!(el instanceof HTMLIFrameElement) || queryString === "") {
      return;
    }

    const appendString = /[?&]/.test(el.src)
      ? "&" + queryString.replace("?", "")
      : queryString;

    el.setAttribute("src", el.src + appendString);
  }
}
